.text {
  font-size: small;
  cursor: pointer;
}
.underline {
  font-size: small;
  /* color: red; */
  cursor: pointer;
  text-decoration: underline;
}

.limitation{
  font-size: small;
}
