/* .tui-image-editor-controls,
.tui-image-editor-submenu {
  display: none !important;
} */

.tui-image-editor-container
  .color-picker-control
  .tui-colorpicker-palette-button {
  font-size: 4px !important;
  border-radius: 50% !important;
  height: 16px;
  left: 300px;
}

.tui-image-editor-container .color-picker-control {
  width: auto !important;
}
