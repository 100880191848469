.p5 { padding: 5px !important; }
.m5 { padding: 5px !important; }


.pl0 { padding-left: 0px !important; }
.pt0 { padding-top: 0px !important; }
.pr0 { padding-right: 0px !important; }
.pb0 { padding-bottom: 0px !important; }

.br10 { border-radius: 10px !important; }
.br4 { border-radius: 4px !important; }

.mt10 { margin-top: 10px !important; }
.mb10 { margin-bottom: 10px !important; }
.ml10 { margin-left: 10px !important; }
.mr10 { margin-right: 10px !important; }

.mt20 { margin-top: 20px !important; }
.mb20 { margin-bottom: 20px !important; }
.ml20 { margin-left: 20px !important; }
.mr20 { margin-right: 20px !important; }


.mt50 { margin-top: 50px !important; }
.mt40 {margin-top: 40px !important;}
.mb50 { margin-bottom: 50px !important; }
.mb140 { margin-bottom: 140px !important;}
.mt140 {margin-top: 140px !important;}

.pt10 { padding-top: 10px !important; }
.pb10 { padding-bottom: 10px !important; }
.pl10 { padding-left: 10px !important; }
.pr10 { padding-right: 10px !important; }

.pt20 { padding-top: 20px !important; }
.pb20 { padding-bottom: 20px !important; }
.pl20 { padding-left: 20px !important; }
.pr20 { padding-right: 20px !important; }

.pt25 { padding-top: 25px !important; }
.pt160 { padding-top: 130px !important;}
.pt70 { padding-top: 70px !important; }
.pb25 { padding-bottom: 25px !important; }
.pl25 { padding-left: 25px !important; }
.pr25 { padding-right: 25px !important; }

.pt50 { padding-top: 50px !important; }
.pb50 { padding-bottom: 50px !important; }

.fs20 { font-size: 20px !important; }
.fs18 { font-size: 18px !important; }
.fs16 { font-size: 16px !important; }
.fs14 { font-size: 14px !important; }
.fs12 { font-size: 12px !important; }
.fs10 { font-size: 10px !important; }

.w-35 { width: 35% !important; }
.no-shadow { box-shadow: none !important; }
.greylight { color: #8F9198 !important; }
.dangerText { color: red !important; }
.dangerBorder { border-color: red !important; }
.successBorder { border-color: green !important; }
.ellipsis { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
.two-line-text { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; }
.text-transform-capitalize { text-transform: capitalize !important};

.text-center { text-align: center;}
