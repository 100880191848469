@font-face {
  font-family: 'Poppins-bold';
  src: url('./fonts/Poppins-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Poppins-medium';
  src: url('./fonts/Poppins-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Poppins-semiBold';
  src: url('./fonts/Poppins-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'SF-UI-Bold';
  src: url('./fonts/sf-ui-display-bold.woff') format('woff');
}
@font-face {
  font-family: 'SF-UI-Light';
  src: url('./fonts/sf-ui-display-light.woff') format('woff');
}
@font-face {
  font-family: 'SF-UI-Medium';
  src: url('./fonts/sf-ui-display-medium.woff') format('woff');
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Regular.ttf') format('ttf');
}

:root {
  --yellow: #F1C40E;
  --white: #FFFFFF;
  --black: #000000;
  --darkgrey: #535353;
  --grey: #8F9198;
  --light: #F3F3F3;
  --nav-black: #1F2026;
  --input-border: #CDD4D9;
}

html, body { height: 100%; }

body {
  margin: 0;
  background-color: #fff;
  color: black;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2 { font-family: 'Poppins-bold'; }
h3, h4 { font-family: 'Poppins-semiBold'; }
h5, h6 { font-family: 'Poppins-medium'; }
p {font-family: 'Poppins'}
/* h4, h5, h6 { font-family: ''; } */
.btn{
  border-radius: 5px;
}

.content-wrapper { margin-top: 82px; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.row { width: 100%; margin: 0px !important; }
u { text-decoration: underline; text-decoration-color: rgb(255 165 0); text-underline-offset: -1px; }
.btn-submit { color: #fff; margin-bottom: 10px; background-color: var(--yellow) !important; border-color: var(--yellow) !important; }
.btn-send { color: #fff; margin-bottom: 10px; background-color: #3D423A !important; height: 5rem; width: 20rem; border-radius: 3rem !important;}
.btn-facebook { padding-top: 28px !important; color: #fff; margin-bottom: 10px; background-color: #1877F2 !important; height: 5rem; width: 20rem ; border-radius: 3rem !important;}
.btn-youtube {  padding: 10px 10px 10px 10px ; font-size: 1.2rem ; font-family: 'Poppins'  ;color: rgb(255, 255, 255);border: 2px solid #e60a0a; background-color: #ec2727 !important; height: 4.5rem; width: 28rem ; border-radius: 2rem !important;}

.btn-darkgrey { color: #fff; margin-bottom: 10px; background-color: var(--darkgrey) !important; border-color: var(--darkgrey) !important; }
.btn-submit:focus { box-shadow: none; }
.btn-submit:hover { color: white !important; }
.btn { max-width: 550px; }
.btn:focus { box-shadow: none !important; }
.btn:hover { box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 10px !important; }
.btn-light { background-color: #eeeeee !important; margin-bottom: 10px }
.hide { display: none !important; }
.show { display: block !important; }

/* Input Fields CSS on Landing Page */

.input-custom .form-control { margin-bottom: 5px; box-shadow: 0px 1px 6px #3B362E1F; border: none; height: 45px; }
#phone-number { height: 100px !important; background-color: yellow !important;}
.input-custom textarea.form-control { margin-bottom: 5px; box-shadow: 0px 1px 6px #3B362E1F; border: none; height: auto; }
.input-custom .form-control:focus { box-shadow: 0px 1px 6px #3B362E1F; }
.input-custom .form-control::placeholder { color: rgb(212, 212, 212); font-weight: lighter; }


/* {{       Navbar CSS       }} */

.navbar { padding-left: 8.33333%; padding-right: 8.33333%; }
.navbar .nav-menu-container { display: flex; margin-bottom: 0px; margin-right: 20px; }
.navbar .nav-menu-container-parent { padding-right: 12px; }
.navbar .nav-logo { padding-left: 12px; }
.navbar .nav-menu-container li { padding: 0px 10px; }
.navbar .nav-menu-container li a { color: var(--nav-black);; text-decoration: none; font-size: 16px; font-family: 'Poppins-medium'; }
.navbar .nav-menu-container li a:hover { border-bottom: 3px solid var(--yellow);}
.navbar-two { height: 82px; padding-left: 8rem; padding-right: 8rem; border-bottom: 1px solid #f1f1f1; }
.navbar-two .nav-menu-container li { padding: 0px }
.navbar-two .nav-menu-container li a { padding: 27px 8px; }
.navbar .login-btn { color: white; border-radius: 0px 0px 30px 30px; background-color: var(--yellow); border: var(--yellow); text-decoration: none; font-weight: bold; height: 85px; width:140px; text-align: center; padding-top: 35px;margin-top: -10px !important ; font-size: 20px; }
.active-nav { border-bottom: 3px solid var(--yellow); }
.arrow {
  display: inline-block;
  /* position: absolute; */
  margin-left: 6px;
  width: 10px;
  height: 10px;
  background: transparent;
  text-indent: -9999px;
  border-top: 2px solid #bfbfbf;
  border-left: 2px solid #bfbfbf;
  transition: all 250ms ease-in-out;
  text-decoration: none;
  color: transparent;
}
.navbar-icon{
  height: 50px;
  width: auto;
}

.profile-dropdown:hover .arrow {
  border-color: gray;
  border-width: 3px;
}

.arrow:before {
  display: block;
  height: 200%;
  width: 200%;
  margin-left: -50%;
  margin-top: -50%;
  content: "";
  transform: rotate(45deg);
}
.arrow.down {
  transform: rotate(-135deg);
  right: -8px;
}

/* {{      Header Text & Banner CSS / Home     }} */

.banner-text-container { position: relative; display: flex; justify-content: flex-end;}
.banner-text-container-left { position: relative; display: flex; justify-content: flex-start;}

.header-left-text { max-width: 31%; position: absolute; top: 50%; left: 0; transform: translate(-0%, -50%);}
.header-left-text span { font-size: 20px; color: black;}
.header-left-text p { font-size: 40px; line-height: 60px; }
.header-left-text a { color: black; border-bottom: 1px solid #bfbfbf; text-decoration: none; }

.header-right-text { max-width: 31%; position: absolute; top: 50%; right: 0; transform: translate(-0%, -50%);}
.header-right-text span { font-size: 20px; color: black;}
.header-right-text p { font-size: 40px; line-height: 60px; }
.header-right-text a { color: black; border-bottom: 1px solid #bfbfbf; text-decoration: none; }

.header-img { width: 70%; }
.insp-text { width: 30% !important; background: #f7f7f7; padding: 15px 30px; border-bottom: 1px solid #eaeaea; float: left; }
.rect-container { position: relative; right: 21%; }
.rectangle { width: 170px; height: 180px; position: absolute; right: 0; top: -104px; color: white; background-color: var(--yellow); }
.dotted-rect-or { position: absolute; right: -50px; top: 25px; }
.header-bottom-text { text-align: center; margin: 100px 0px !important; line-height: 80px; }
.header-bottom-text p { font-size: 100px; color: #f5f5f5; margin-bottom: 0px; }

/* {{     Features Section CSS     }} */

.feature-container { padding-left: 100px !important; padding-bottom: 100px !important; padding-top: 100px !important; }
.ft-img-container { position: relative; margin-bottom: 50px; }
.ft-img-container .ft-img-fixed { z-index: -1; position: absolute; left: 23px; top: -93px; }
.feature-container .ft-heading-container { position: relative; width: 85%; }
.feature-container .ft-heading-container img { width: 35px; position: absolute; left: -80px; top: 28px; }
.feature-container .ft-heading-container p { width: 75%; line-height: 27px; margin-top: 30px; }
.Right-ft-top { margin-top: 120px !important; }
.left-ft-container .feature-container:nth-child(2){ border-right: 2px solid #dadada; }


.contactUs-container .form-center { width: 60%; margin: 80px auto; }
.contactUs-container .form-center form > * { padding: 10px 20px; margin-bottom: 20px; width: 60%; border: none; box-shadow: rgb(67 71 85 / 10%) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.05em 0.5em; resize: none;}
.contactUs-container .form-center form > *::placeholder { color: rgb(212, 212, 212); font-weight: lighter;  }
input, textarea, button:focus { outline: none; }
.contactUs-container .form-center form .btn-submit { width: 290px; padding: 14px 20px; color: white; background: var(--yellow); font-size: 25px; font-weight: 600; margin-top: 80px; }
.input-logo { background-image:url("./message.png"); background-position:96%; background-repeat:no-repeat; padding-left:1px; }
.mail-container { height: 150px; line-height: 160px; background: #fbfbfb; display: flex; justify-content: space-around; align-items: center; }
.mail-container p { font-size: 18px; font-weight: 500; margin: 0px;}
.mail-container input { display: inline-block; vertical-align: middle; line-height: normal; padding: 10px 20px; border: none; box-shadow: rgb(67 71 85 / 10%) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.05em 0.5em; }

/* Footer Section CSS */

.footer { position: absolute; left: 0; bottom: 0; height: 200px; width: 100%; background-color: #fff; }
.footer-logo { width: max-content; margin: 0px auto; }
.footer-logo h1 { font-weight: 700; }
.footer-menu { list-style: none; display: flex; justify-content: space-around; margin-top: 10px; padding: 0px; margin-bottom: 0px; }
.footer-menu li a { text-decoration: none; color: grey; font-size: 18px; font-weight: 500; }
.footer-menu li a:hover { color: var(--yellow); }
.footer-social-icons { width: max-content; margin: 0px auto; margin-top: 11px; }
.footer-social-icons img { margin: 0px 15px; }
.footer-background {background-color: #f1f1f1; padding-bottom: 50px; margin-left: -8vw;
  padding-left: 8vw;
  margin-right: -8vw;
  padding-right: 8vw;}

  /* Properties for Blogs on landing page */
  .blog-main-image { height: 100%; width: 100%; border-radius: 25px;}
  .blog-secondary-image {max-height: 245px; height: 100%; width: 100%; border-radius: 25px; margin-top: 5px;}



/* Padding from top of components  */


#features, #pricing, #support, #about-us, #contact-us { padding-top: 100px; }


/* LOGIN CSS */


.login-img-container { height: inherit; }
.login-img-container img { width: 100%; min-height: 850px; object-fit: cover; }
/* .login-img-container .PrTermImg { width: 390px; height: 820px; object-fit: cover; margin-right: 50px; }  */
.signIn-container { min-height: 100vh; }
.auth-form-container { position: relative; height: 100%; width: 100%; }
.auth-form-container .form-wrapper { margin: 0; margin-bottom: 100px; position: relative;
   transform: translateY(-50%); width: 50%; }
.auth-form-container .login-form { top: 40%; left: 20% }
.auth-form-container .userprof { top: 50%; left: 20% }
.formimg img { min-height: 760px; }
.auth-form-container .edit-profile { width: 70%; margin: auto }
.input-custom { margin-bottom: 15px; max-width: 550px; }
.auth-form-container .input-custom { margin-bottom: 13px; }
.auth-form-container .auth-form-text { margin-bottom: 30px; text-align: justify; }
.auth-form-container .auth-form-text small { font-size: 12px; color: #c5c5c5; line-height: 0px; }
.auth-form-container a { line-height: 27px; }
.auth-form-container button.btn, .auth-form-container a { width: 100%; max-width: 550px; height: 45px; }
.auth-form-container button.btn i, .auth-form-container a i { float: right; padding-top: 6px; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
}


/* SignUp CSS */

.signUp-social-icons-container { display: flex; margin-bottom: 20px; }
.signUp-social-icons-container .signup-icon-container { color: white; border-radius: 4px; background-color: #afafaf; padding: 12px; text-align: center; }
.signup-icon-container:first-child { margin-right: 5px; width: 64%; display: flex; align-items: center; letter-spacing: 0.2px; font-size: 15px; }
.signup-icon-container:first-child img { margin-right: 30px; }
.signup-icon-container:nth-child(2) { margin-right: 2.5px; width: 19%; }
.signup-icon-container:nth-child(3) { margin-left: 2.5px;  width: 19%; }
.sZ-fields { display: flex; }
.sZ-fields div:first-child { margin-right: 10px; width: 100%; }
.sZ-fields div:last-child { margin-left: 10px; width: 100%; }

/* Privacy Policy CSS */

.privacy-policy-container { padding: 50px 20px 0px 30px; }
.privacy-policy-container p { color: #8c8c8c; }

/*
NavBar CSS
*/

.profile-text{
  max-width: 150px;
  font-family: "Poppins-medium";
}

/* Dashboard Page CSS */

.side-spacing { padding: 6rem 9rem; }
.profile-dropdown { padding: 5px 10px 5px 10px; border: none !important; background: transparent !important; color: black !important; text-align: start !important; }
.profile-dropdown:hover { box-shadow: none !important; }
.profile-dpMenu { right: -10px; left: unset !important; min-width: 180px; padding: 0px; }
.profile-dpMenu .dropdown-item { padding: .60rem 0.90rem; }
.dropdown-toggle::after { content: none !important; }
.profile-img-ct { width: 50px; height: 50px; border-radius: 50%; }
.profile-img { width: 50px; height: 50px; border-radius: 50%; object-fit: cover; }
.profile-container { display: flex; align-items: center; }
.content-container { margin-bottom: 200px; margin-top: 82px; } 
.create-inspection-ct { min-height: 555px; }
.new-Ins-btn { display: flex; align-items: center; font-size: 15px; background: transparent; border: none; }
.circlePlus { width: 40px; color: white; margin-right: 15px; height: 40px; float: left; background-color: #F1C40E; text-align: center; font-size: 23.5px; box-shadow: 4px 6px 12px #f1c40e88; border-radius: 50%; }
.create-inspection-ct .text-ct p { color: #8F9198; }
.create-inspection-ct .text-ct button { padding: 0px; color: var(--yellow); text-decoration: none; text-transform:uppercase !important; font-weight: 500; letter-spacing: 0.3px; }
.card-custom { text-align: center; max-width: 850px; border-radius: 13px; border: none; box-shadow: 0px 0px 10px #DBD8EA80; }
.ins-box-ct {margin: 5px 0px 5px 15px;} 
.card-Inspection-wd { width: auto; }
.card-Inspection { min-height: 350px; height: auto; max-width: 250px; }
.circle-outer { width: 82px; padding: 5px; height: 82px; border: 1px solid; border-radius: 50%; margin: auto; border: 1px solid var(--yellow); }
.circle-name { width: 70px; font-weight: 600; height: 70px; border-radius: 50%; background-color: #FBF1C8; display: flex; 
      justify-content: space-evenly; flex-direction: column; font-size: 27px; align-items: center; color: var(--yellow); }
.circle-name-skel { width: 70px; height: 70px; border-radius: 50%; display: flex; justify-content: space-evenly; flex-direction: column; align-items: center; }
.circle-skel-br span br { display: none; }
.inspection-card-text { color: lightgray; line-height: 25px; font-size: 14px; }
.inspection-card-text strong { font-weight: 500; color: #868686; }
.inspStepImgCt { height: 60px; display: flex; flex-direction: column; justify-content: space-around; }
.edit-inpection-btn { position: relative; bottom: 20px; left: 0; right: 0; margin-top: 50px; }
.edit-inpection-btn button { color: white; background-color: var(--yellow); border: var(--yellow); }
.view-all-inspection { float: right; margin-top: 20px; text-decoration: none; }
.inspStepsCt .card-custom { height: 130px }
/* .menuCard-img { width: 60px; } */
.menuCard-height { height: 160px; }
.menu-links-ct { background-color: #f9f9f9; }
.quick-help h2 { font-weight: 700; }
.quick-help .text { width: 40%; }
.card-mid-text { width: 92%; }
.Mcontent { margin-left: 12rem; margin-right: 12rem; }
.height-unset { min-height: unset !important; }
.custom-card-content { display: flex; align-items: center; }
.custom-card-content img { margin-right: 10px; }

/* Inspection List and Reports */

.insp-list-ct { display: flex; align-items: center; }
.selectbtn { background: #F1C40E; border: 1px solid #CDD4D9; border-radius: 8px; opacity: 0.61; text-decoration: none; color: white; padding: 15px 50px; }
.rep-dropdown-ct { display: flex; align-items: center;}
.rep-dropdown-ct select { border: 1px solid #CDD4D9; border-radius: 8px; opacity: 0.61; cursor: pointer; padding: 15px 7px; width: 100%; }

.custom-file-input { text-align: center; cursor: pointer; }
.custom-file-input .choose-file { padding: 8px; }
.custom-file-input input[type="file"] { display: none; }
.choose-file-box { text-align: center; background: #FFFFFF; border: 1px solid #CDD4D9; border-radius: 8px; padding: 20px; }
.choose-file-box .btn { background: #FCFBFB; border: 1px solid #D4D4D4; border-radius: 21px; opacity: 1; }
.box-rt { padding: 14px 7px; }
.add-image { width: 100%;  height: 180px; }
.add-image-logo { width: 60px; height: 50px; margin: 25px 0px }
.choose-file-box label { font-weight: 600; margin-right: 10px; font-size: 15px; }
.choose-file-box label input[type="checkbox"] { transform: scale(1.2); margin-right: 6px; }
input[type="file"] {
  display: none;
}
.br-danger { border-color: red !important; }
.input-error { color: red; letter-spacing: 0.5px;}
.img-holder { width: 100px; height: 100px; border-radius: 4px;}
.reg-logo { width: inherit; height: inherit; object-fit: cover; }
.upl-ct { box-shadow: 0px 1px 6px #3b362e1f; padding: 20px; width: 100%; text-align: center; cursor: pointer; }
.PhoneInput .PhoneInputInput { 
    box-shadow: rgb(187 187 187 / 30%) 0px 4px 4px;
    border: 1px solid #d6d6d6;
    height: 45px;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
 }
 .PhoneInputInput {
   max-width: 210px;
   float: right;
 }
 .PhoneInput { justify-content: center; }
 .otp-input { width: 210px; margin: 0px auto; }
.PhoneInputCountryIcon svg {
  vertical-align: unset;
  display: flex;
}
.rc-anchor-light.rc-anchor-normal {
  text-align: center;
}
#recaptcha-container div {
  margin: 0px auto;
  margin-bottom: 20px;
}
.phone-input-ct { display: none; }
button.close { background-color: transparent; border: none; }
.acc-header { padding: 25px 25px 25px 25px; cursor: pointer; }
.profImgCt .profile-img { width: 100%; height: 100%; }
.accordion-content { padding: 0px 30px 30px 30px; }
.profImgCt.noB { border: none; }
.profImgCt { position: relative; width: 125px; height: 125px; border-radius: 50%; border: 1px solid #d6d6d6; }
.profImgCt .edit-prof { position: absolute; top: 10px; width: 29px; right: 0; cursor: pointer; background: transparent !important; border-radius: 13px;}
.profImgCt .edit-prof:hover { box-shadow: 4px 6px 12px #f1c40e88; }

.topToBottom {
  animation: topToBottom ease 0.7s;
  -webkit-animation: topToBottom ease 0.7s;
  -moz-animation: topToBottom ease 0.7s;
  -o-animation: topToBottom ease 0.7s;
  -ms-animation: topToBottom ease 0.7s;
}
.drp-shadow { box-shadow: 0px -1px 14px #0000001A; border: none; border-radius: 13px; }
.drp-shadow.dropdown-menu .dropdown-item { font-family: 'Poppins-medium'; font-size: 15px; }
.dotsImg { background-color: transparent; box-shadow: unset; }
.drp-shadow.dropdown-menu { transform: unset !important; inset: unset !important; left: auto; right: 0 !important; }
.dotsImg:hover { box-shadow: unset !important;}
.dots-drop { position: absolute; right: 10px; top: 34px; }
.dots-drop .dropdown-item { display: flex; align-items: center;}
.dots-drop .dropdown-item span { padding-right: 10px; width: 25px; display: flex; justify-content: space-around; align-items: center;}


/* CREATE INSPECTION CSS */
.insp-tabs { justify-content: space-around;border-radius: 13px; padding: 10px 0px; box-shadow: 0px 0px 10px #DBD8EA80; border: none; }
.insp-tabs .nav-item button { text-transform: capitalize; color: var(--nav-black); border: unset; padding: 0.5rem 0px;}
.btn.no-shadow:hover { box-shadow: none !important; }
.insp-tabs .nav-item.show .nav-link, .insp-tabs .nav-link.active { border: none; font-family: Poppins-bold;  }
.insp-tabs .nav-link:focus, .insp-tabs .nav-link:hover { border: unset; isolation: isolate; }
.stepCat-ct { box-shadow: 0px 1px 6px #3B362E1F; border-radius: 12px; padding: 30px 20px; }
.circle-card-ct{ margin: 10px 0px; }
.tab-content .circle-card-ct .circle-name { width: 50px; height: 50px; font-size: 20px; font-weight: 600;  } 
.custom-formCheck { padding-left: 2.5em; }
.custom-formCheck .form-check-input { margin-left: -2.5em;}
.modal-body { padding: 0px; }
.modal-body .para { background-color: var(--light); color: var(--grey); padding: 1rem; font-size: 18px; margin-bottom: 10px; border-radius: 4px }
.custom-Modal .modal-dialog .modal-content { padding: 1.5rem; }
.custom-Modal .modal-dialog .modal-content .modal-header { border: none; padding-top: 0px; }
.custom-Modal .modal-dialog .modal-content .modal-footer { border: none; padding-bottom: 0px; }
.custom-Modal .modal-dialog .modal-content .modal-footer button { line-height: 2; }
.circle-card-ct.active .circle-name { background-color: var(--yellow); color: white; }
.circle-card-ct.active h5 { font-weight: 900; letter-spacing: 0.4px; }
.cursor-pointer { cursor: pointer !important }
.break-word { word-break: break-word !important; }
.active-bookmark { border-bottom: 4px solid var(--yellow); }
.loading-indicator:before {content: ''; background: #000000cc; opacity: 0.3; position: fixed; width: 100%;
  height: 100%; top: 0; left: 0; z-index: 1058; }
.loading-indicator:after { content: ''; position: fixed;top: 50%;left: 50%; transform: translate(-50%, 0);
  z-index: 1059; border: 10px solid #f3f3f3; /* Light grey */
  background-color: transparent !important; border-top: 12px solid var(--yellow); border-radius: 50%;
  width: 80px; height: 80px; animation: spin 2s linear infinite; }


/* REVIEW INSPECTION */
.reviewHeading { font-size: 40px }

.custom-overflow::-webkit-scrollbar { width: 7px; }

/* Track */
.custom-overflow::-webkit-scrollbar-track { background: #f1f1f1; border-radius: 5px; }
 
/* Handle */
.custom-overflow::-webkit-scrollbar-thumb { background: rgb(170, 170, 170); border-radius: 5px; }
/* Handle on hover */
.custom-overflow::-webkit-scrollbar-thumb:hover { background: rgb(143, 143, 143); }

.search-input { position: absolute; top: -6px; right: 25px; min-width: 250px; }
.slick-slide img {
  margin: auto;
}
.slick-list .slick-track { padding: 15px 0px; }
.slider-container { padding: 30px 40px; }
.slick-prev:before, .slick-next:before { color: #7e7e7e; }
.PR { position: relative;
}
.zoom {
  color: black;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tui-image-editor-header-logo { display: none !important; }
.tui-image-editor-submenu { background: #efefef; opacity: 0.7 !important; }
  .tui-image-editor-button.apply, 
  .tui-image-editor-button.cancel, 
  .tie-crop-button.action { display: flex !important; justify-content: center; align-items: center !important;}
.tui-image-editor { width: 100% !important; height: 100% !important; max-height: 700px !important; top: unset !important; }
.tui-image-editor-container .tui-image-editor-help-menu.top {
  transform: unset !important;
  -webkit-transform: unset !important;
  left: unset
}
.tui-image-editor-canvas-container { margin: auto !important }
.tui-image-editor-container .tui-image-editor-menu > .tui-image-editor-item, .tui-image-editor-container .tui-image-editor-help-menu > .tui-image-editor-item { margin: 0px !important;}
.tui-image-editor-help-menu { display: flex !important }
.zoom span {
  cursor: pointer;
  font-size: 30px;
  margin: 0 20px
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/*review inspection css
*/
.review-inspec-start{
  padding-bottom: 15px; 
  margin-right: 100px;
}
.review-inspec-end{
  padding-bottom: 15px;
}

.table-responsive{
  padding-left: 3rem;
  padding-right: 3rem;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes topToBottom {
  0% {
    margin-top: -10%;
  }
  100% {
    margin-top: 0%;
  }
}

@-moz-keyframes topToBottom {
  0% {
    margin-top: -10%;
  }
  100% {
    margin-top: 0%;
  }
}

@-webkit-keyframes topToBottom {
  0% {
    margin-top: -10%;
  }
  100% {
    margin-top: 0%;
  }
}

@-o-keyframes topToBottom {
  0% {
    margin-top: -10%;
  }
  100% {
    margin-top: 0%;
  }
}

@-ms-keyframes topToBottom {
  0% {
    margin-top: -10%;
  }
  100% {
    margin-top: 0%;
  }
}

@media only screen and (min-width: 1080px){
  #inspection-cards-main{
    display: flex;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1420px) {
  .navbar-two {
      height: 82px;
      padding-left: 12rem;
      padding-right: 12rem;
  }
  .side-spacing {
      padding: 6rem 14rem;
  }
  
}
@media only screen and (max-width: 1350px) {
  .navbar-two {
      height: 82px;
      padding-left: 4rem;
      padding-right: 4rem;
  }
  .side-spacing {
      padding: 1rem !important;
  }
  .navbar .nav-menu-container li a {
    font-size: 14px !important;
  }
  .navbar-two .nav-menu-container li a {
    padding: 27px 7px;
  }
  .menu-links-ct { padding: 3rem 1rem !important; }
}

@media only screen and (max-width: 1020px){
  .navbar .nav-menu-container li a {
    font-size: 10px !important;
  }
  .navbar-two .nav-menu-container li a {
    padding: 10px 3px;
  }
  .text-ct p{
    
    width: 80vw;
  }
  .text-ct h2{
    
    width: 80vw;
  }
  .text-ct button{
    
    width: 80vw;
  }
  /* .d-flex{
    display: block !important;
  } */

}


.billing-text{
  color: #474646;
}
.text-center{
  text-align: center;
}


@media screen and (max-width: 1024px){
  #react-burger-menu-btn{
    z-index: 0 !important;
  }
  .col-md-1{
    visibility: hidden;
  }
  .col-md-10{
    width: 97vw;
  }
  .banner-text-container{
    display: block !important;
    width:100%
  }
  .banner-text-container-left{
    display: block !important;
    width: 100%
  }
  .header-left-text{
    display: block !important;
    max-width: 100%;
    position: static;
    transform: none;
  }
  .header-right-text{
    display: block !important;
    max-width: 100%;
    position: static;
    transform: none;
  }
  .banner-path{
    display: none;
  }
  .Mcontent { margin-left: 3rem ; margin-right: 3rem  ; }
  .footer-background{
    margin-left: 0%;
    margin-right: 0%;
  }
  
  

}

@media only screen and (max-width: 700px){
  .navbar .nav-menu-container li a {
    font-size: 10px !important;
  }
  .navbar-two .nav-menu-container li a {
    padding: 10px 3px;
  }
  .text-ct p{
    
    width: 80vw;
  }
  .text-ct h2{
    
    width: 80vw;
  }
  .text-ct button{
    
    width: 80vw;
  }
  

}

@media screen and (max-width: 600px){
  .nav-logo{
    display: none;
  }
  .notification-text{
    font-size: 0.8rem;
  }
  .nav-menu-container{
    display: none;
    visibility: hidden;
  }
  .auth-form-container .form-wrapper{
    transform: translateY(-40%);
    width: 100%;
    left: 0%
  }
  .Mcontent { margin-left: 0px ; margin-right: 0px; }
  .login-img-container img{
   min-height: 300px;
  }
  
  .btn-youtube{
    width: 17rem;
    max-width: 600px;
    height: 6.5rem;
    padding: 10px 10px 10px 10px;
  }
  .btn-facebook{
    width: 15rem
  }
  .btn .btn-send{
    width: 15rem !important;

  }
  .contactUs-container .form-center{
    width: 100%;
    margin: 10px
  }
  .col-lg-3{
    max-width: 50%;
  }
  .Navbar-text{
    visibility: hidden;
    display: none;
  }
  .nav-menu-container-parent{
    display: flex !important;
  }
  .navbar{
    padding-right: 0px;
    padding-left: 3%;
    height: 70px;
  }
  .navbar .login-btn {
    padding-top: 30px;
    height: 80px;
    margin-top: -20px !important;
    
  }
  .header-left-text{
    text-align: center;
  }
  .header-right-text{
    text-align: center;
  }
  .header-img{
    width: 100%;
  }
  .text-center .row {
    display: flex;
  }
  .blog-col{
    flex: none;
  }
  .blog-col>.row{
    padding-left: 10px;
    padding-right: 0px;
  }
  .blog-col>img{
    padding-left: 10px;
  }
  #inspection-cards{
    display: block !important;
    padding: 1rem;
  }
  #inspection-header{
    display: block !important;
  }
  #react-burger-menu-btn{
    z-index: 0 !important;
  }
  .btn-plans{
    max-width: 50% !important;
  }
  .profile-text{
    font-size: 0.8rem ;
    max-width: 90px;
  }
  .popup-button{
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .pl0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .pr0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .card-custom{
    margin-bottom: 1rem;
  }
  .btn-submit{
    margin-bottom: 10px !important;
  }
  .inspection-date{
    display: block !important;
  }
  .review-inspec-start {
    margin-right: 0px;
}
  .profHead{
    display: block !important;
  }
  #profile-detail{
    display: block !important;
    margin-top: 10px;
  }
  .btn-edit-profile{
    width: 5rem;
    border-radius: 20px;
    margin-left: 20px;
    margin-top: 10px;
  }
  .list-header{
    display: grid;
  }
  .search-list{
    display: flex;
    justify-content: flex-end;
  }
  #searchField{
    width: 93%;
  }


}
.bm-item{
  text-decoration: none;
  font-size: 25px;
  margin: 15px;
  
}
.bm-item-list > .bm-item{
  color: black !important;
}
.bm-item-list > .active-nav{
  border-bottom: 3px solid black;
}
.bm-burger-button{
  height: 30px;
  width: 30px;
}
.bm-overlay{
  /* background-color: rgb(212, 212, 212, 0.9) !important; */
  background-color: rgb(240, 200, 5, 1.0) !important;
  top: -1px;
  left: -1px;
}
.bm-item-list{
  overflow-y: hidden;
}

.notification-dropdown{
  left: -250px !important;
  background: 'white' !important;
  min-width: 550px !important;
  text-align: 'center' !important;
  box-shadow: '0 20px 20px rgb(0 0 0 / 8%' !important;
  border-radius: 15px !important;
  text-align: center !important;
}

@media screen and (max-width: 1200px){
  .notification-dropdown{
  min-width: auto !important;
  left: -105px !important;
  top: 130% !important
  
  }
}

#archive-spacing{
  padding: 6rem 4rem !important;
}

.notification-text{
  text-align: start;
}